<template>
  <div class="pb-4">
    <div class="subsection">
      <h1>Balancer Privacy&nbsp;Policy</h1>
      <p><em>Last Updated: June 2022</em></p>
      <p>
        <em class="font-medium">
          This Privacy Policy explains how the Balancer Foundation, its
          subsidiary, Balancer OpCo Limited, and material service providers
          operating under a legal agreement (“Balancer Foundation,” “Balancer,”
          “we,” “our,” or “us”) collects, uses, and discloses information about
          you. This Privacy Policy applies when you use our website, Balancer
          Protocol user-interface or application and other online products
          (collectively, our “UI”), engage with us on social media, or otherwise
          interact with us.
        </em>
      </p>
      <p>
        <em class="font-medium">
          We may change this Privacy Policy from time to time. If we make
          changes, we will notify you by revising the date at the top of this
          policy and, when material, we will provide you with additional notice
          by adding a statement to our website and consent as required under
          applicable law. Your continued use of this UI after we make changes is
          deemed to be acceptance of those changes when permissible. We
          encourage you to review this Privacy Policy regularly to stay informed
          about our information practices and the choices available to you.</em
        >
      </p>
    </div>
    <div class="subsection">
      <h2>Collection of Information</h2>
      <h3>Information You Provide to Us</h3>

      <p>
        We do not typically request, collect or use personal information from
        you except under limited circumstances as described herein. With the
        exception of your wallet address, there is no reason for you to provide
        personal information when you use the UI. Your use of the UI will not be
        customized and this policy reflects that practice.
      </p>
      <p>
        Balancer is not directed to children under the age of 16. If a parent or
        guardian becomes aware that his or her child has provided us with
        personal information without your consent, please contact us at
        <a class="link" href="mailto:privacypolicy@balancer.finance"
          >privacypolicy@balancer.finance</a
        >. If we become aware that a child under the age of 16 has provided us
        with personal information, we will take reasonable efforts to delete
        such personal information.
      </p>

      <h3>
        Information We Collect Automatically When You Interact With&nbsp;Us
      </h3>
      <p>
        When you access or use our UI, we may automatically collect certain
        information, including:
      </p>
      <ul>
        <li>
          <em class="font-semibold"> Device and Usage Information:</em> We (and
          our service providers) collect information about how you access the
          UI, including data about the device and network you use, such as your
          hardware model, operating system version, mobile network, browser
          type, IP address and app version. We do not typically, but we may,
          also collect information about your activity on the UI, such as access
          times, pages viewed, links clicked, and the page you visited before
          navigating to the UI.
        </li>
        <li>
          <em class="font-semibold"
            >Information Collected by Cookies and Similar Tracking
            Technologies:</em
          >
          We do not typically use tracking technologies, such as cookies and web
          beacons, to collect information about you. Cookies are small data
          files stored on your hard drive or in device memory that help us
          improve the UI and your experience, see which areas and features of
          the UI are popular, and count visits. Web beacons (also known as
          “pixel tags” or “clear GIFs”) are electronic images that we use on the
          UI to help deliver cookies, count visits, and understand usage and
          campaign effectiveness. For more information about cookies and how to
          disable them, see our
          <router-link :to="{ name: 'cookies-policy' }">
            <span className="link">Cookies Policy</span>
          </router-link>
          and the Your Choices section below.
        </li>
      </ul>

      <h3>Information We Collect from Other&nbsp;Sources</h3>

      <p>
        We do not typically obtain information from third-party sources in order
        to provide business services. We do not sell information we collect.
      </p>

      <h3>Information We Derive</h3>

      <p>
        We may derive limited information or draw inferences about you based on
        the information we have access to or receive, most importantly, from our
        service providers. Your wallet and IP address is accessible to Balancer
        and its vendor(s). We may make inferences about your location based on
        your wallet or IP address.
      </p>
    </div>
    <div class="subsection">
      <h2>Use of Information</h2>

      <p>
        We do not collect your personal information in order to customize the UI
        for your use. However, we reserve the ability to use information we
        collect to provide, maintain, administer and/or improve the UI. We may
        also use the information we collect to:
      </p>
      <ul>
        <li>Ensure proper functioning of Balancer and the UI;</li>
        <li>
          Provide services, content, material and other information on the UI;
        </li>
        <li>Identify and/or diagnose problems on or related to the UI;</li>
        <li>
          Send technical notices, security alerts, and support and
          administrative messages;
        </li>
        <li>
          Provide requested information, technical support and/or integrations
          such as an application programming interface or API;
        </li>
        <li>Respond to comments and questions;</li>
        <li>
          Analyze trends, usage, and activities in connection with the UI;
        </li>
        <li>
          Detect, investigate, and prevent security incidents and other
          malicious, deceptive, fraudulent, or illegal activity and protect the
          rights and property of Balancer and others;
        </li>
        <li>Debug to identify and repair errors in the UI;</li>
        <li>Comply with our legal, regulatory and financial obligations;</li>
        <li>
          Carry out any other purpose described to you at the time the
          information was collected; and
        </li>
        <li>
          For other reasonable internal use or uses aligned with your
          relationship with us and the context in which we collected the
          information.
        </li>
      </ul>
    </div>
    <div class="subsection">
      <h2>Sharing of Information</h2>

      <p>
        We do not have access to or share personally identifiable information in
        the normal course of Balancer business. However, when Balancer has
        access to personal information, such as your wallet and certain IP
        addresses, it may share that information in the following circumstances
        or as otherwise described in this policy:
      </p>
      <ul>
        <li>
          We share personal information with vendors, service providers, and
          consultants that need access to personal information in order to
          perform services for us, such as transaction monitoring, data
          management, fraud prevention, customer service and support, marketing
          and/or advertising.
        </li>
        <li>
          If you choose to use integrations, we may share certain information
          with the integration partners.
        </li>
        <li>
          We may disclose personal information if we believe that disclosure is
          in accordance with, or required by, any applicable law or legal
          process, including lawful requests by public authorities to meet
          national security or law enforcement requirements.
        </li>
        <li>
          We may share personal information if we believe that your actions are
          inconsistent with our user agreements or policies, if we believe that
          you have violated the law, or if we believe it is necessary to protect
          the rights, property, and safety of Balancer, the Balancer ecosystem,
          the public, or others.
        </li>
        <li>
          We share personal information with our lawyers and other professional
          advisors where necessary to obtain advice or otherwise protect and
          manage our business interests.
        </li>
        <li>
          We may share personal information in connection with, or during
          negotiations concerning, merger, sale of company assets, financing,
          bankruptcy, business closure, or acquisition of all or a portion of
          our assets. Additionally, as part of such an event, we may transfer or
          sell personal information to a third party. We will provide notice to
          you on our UI of any such sharing to a third party and any choices you
          may have regarding the sharing of your personal information.
        </li>
        <li>
          Personal information may be shared between and among Balancer and our
          current and future parents, affiliates, and subsidiaries and other
          companies under common control and ownership (“corporate affiliates”).
          This information may be used to provide you with offers, services, or
          products that may be of interest to you and provide you with their
          products and services. Any such corporate affiliate may use your
          personal information only according to the terms of this Policy. If
          you are located in a jurisdiction where such sharing requires your
          permission, we will only share such information with your consent. If
          you decide you no longer wish to receive these promotional
          communications, please follow the instructions provided in Your
          Choices section below.
        </li>
        <li>
          We share personal information with your consent or at your direction.
        </li>
        <li>
          We also share aggregated or de-identified information that cannot
          reasonably be used to identify you.
        </li>
      </ul>
    </div>
    <div class="subsection">
      <h2>Advertising and Analytics</h2>

      <p>
        We do not work with third parties to serve ads to you as part of
        customized campaigns on the UI or third-party UIs. Balancer may allow
        others to provide analytics services on our behalf across the web and in
        mobile apps. These entities may use web beacons, device identifiers, and
        other technologies to collect information about your use of the UI and
        other websites and applications, including your wallet address, web
        browser, mobile network information, pages viewed, time spent on pages
        or in mobile apps, links clicked, and conversion information.
      </p>
    </div>
    <div class="subsection">
      <h2>
        Transfer of information to the United States and Other&nbsp;Countries
      </h2>

      <p>
        Balancer Foundation is headquartered in the Cayman Islands with a
        subsidiary in the British Virgin Islands (BVI). Therefore, we and our
        service providers may transfer your personal information to, or store or
        access it in, jurisdictions that may not provide levels of data
        protection that are equivalent to those of your home jurisdiction. We
        will take steps to ensure that your personal information receives an
        adequate level of protection in the jurisdictions in which we process
        it.
      </p>
    </div>
    <div class="subsection">
      <h2>Your Choices</h2>
      <h3>Cookies</h3>
      <p>
        Balancer does not typically use any cookies. It reserves the ability to
        use them to affect the availability and functionality of the UI. For
        more information about cookies and how to disable them, see our
        <router-link :to="{ name: 'cookies-policy' }">
          <span className="text-blue-500 hover:text-blue-800 transition-colors"
            >Cookies Policy</span
          > </router-link
        >.
      </p>
    </div>
    <div class="subsection">
      <h2>Additional Considerations</h2>

      <p>
        In the preceding 12 months, we or our vendors may have collected the
        following categories of personal information: identifiers, internet or
        other electronic network activity information and inferences. For
        details about the data points we collect and the categories of sources
        of such collection, please see the Collection of Information section
        above. We collect personal information for the purposes described in the
        Use of Information section above. In the preceding 12 months, we have
        disclosed the following categories of personal information for business
        purposes to the following categories of recipients:
      </p>

      <table border="1" cellpadding="0" cellspacing="0" style="width: 100%">
        <tr>
          <th>Category of Personal Information</th>
          <th>Type of Information &amp; Categories of Recipients</th>
        </tr>
        <tr>
          <td>Identifiers</td>
          <td>
            We share with vendors such as TRM Labs and CloudFlare: wallet
            address, certain IP addresses, device identifiers or other similar
            identifiers
          </td>
        </tr>
        <tr>
          <td>Internet or other electronic network activity information</td>
          <td>
            We share with vendors: information regarding an interaction with an
            UI/website
          </td>
        </tr>
      </table>

      <p>
        Balancer does not “sell” personal information to advertise our products
        to you or otherwise.
      </p>

      <p>
        Subject to certain limitations, you have the right to (1) request to
        know more about the categories and specific pieces of personal
        information we collect, use, and disclose, and sell, (2) request
        deletion of your personal information, (3) opt out of any “sales” of
        your personal information that may be occurring, and (4) not be
        discriminated against for exercising these rights. You may make these
        requests by emailing us at
        <a class="link" href="mailto:privacypolicy@balancer.finance"
          >privacypolicy@balancer.finance</a
        >. If we receive your request from an authorized agent, we may ask for
        evidence that you have provided such agent with a power of attorney or
        that the agent otherwise has valid written authority to submit requests
        to exercise rights on your behalf. If you are an authorized agent
        seeking to make a request, please contact us.
      </p>

      <h3>Shine the Light</h3>

      <p>
        Balancer does not share such information with third parties for direct
        marketing purposes.
      </p>

      <h3>Do Not Track Signals</h3>

      <p>
        Our Services do not respond to “Do Not Track” signals communicated by
        your browser. We do not knowingly retain or sell tracking information
        collected about your online activity. For more information about Do Not
        Track, please visit
        <a class="link" target="_blank" href="https://allaboutdnt.com/"
          >www.allaboutdnt.com</a
        >.
      </p>
    </div>
    <div class="subsection">
      <h2>Additional Disclousures for Individuals in Europe</h2>

      <p>
        If you are located in the European Economic Area (EEA), the United
        Kingdom, or Switzerland, you have certain rights and protections under
        the law regarding the processing of your personal data, and this section
        applies to you.
      </p>
      <h3>Legal Basis for Processing</h3>

      <p>
        Balancer does not typically process user personal data. If Balancer
        processes data, such as a wallet address and certain IP addresses, it
        will do so in reliance on the following lawful bases:
      </p>
      <ul>
        <li>
          To perform our responsibilities under our contract with you (e.g.,
          providing access to the UI you requested).
        </li>
        <li>
          When we have a legitimate interest in processing your personal data to
          operate the UI or protect our interests (e.g., to adhere with
          applicable laws, rules and regulations, provide, maintain, and improve
          our products and UI, conduct data analytics, and communicate with
          you).
        </li>
        <li>To comply with our legal obligations.</li>
      </ul>
      <h3>Data Retention</h3>

      <p>
        We do not maintain customer accounts or store personal data. Our vendor
        may store your IP address.
      </p>
      <h3>Data Subject Requests</h3>
      <p>
        Subject to certain limitations, you have the right to request access to
        the personal data we hold about you and to receive your data in a
        portable format, the right to ask that your personal data be corrected
        or erased, and the right to object to, or request that we restrict,
        certain processing. Balancer does not typically hold or store such
        personal data.
      </p>
    </div>
    <div>
      <h2>Contact Us</h2>

      <p>
        If you have any questions about this Privacy Policy, please contact us
        at
        <a class="link" href="mailto:privacypolicy@balancer.finance"
          >privacypolicy@balancer.finance</a
        >.
      </p>
    </div>
  </div>
</template>
